import React from "react";
import { graphql } from "gatsby";
import GatsbyImg from "gatsby-image";
import styled from "styled-components";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import { BLOCKS } from "@contentful/rich-text-types";

function StandardPageTemplate({ data }) {
  const { title, body } = data.contentfulPage;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const imageID = node.data.target.sys.id;
        const { fluid } = body.references.find(
          ({ contentful_id: id }) => id === imageID
        );

        return <GatsbyImg css="margin-top: 1em" fluid={fluid} />;
      },
    },
  };

  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle>{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      <MaxWidthBox>
        <RichText>{renderRichText(body, options)}</RichText>
      </MaxWidthBox>
    </Layout>
  );
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      slug
      body {
        raw
        references {
          ... on ContentfulAsset {
            id
            contentful_id
            __typename
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;
